import React from "react"
import IndexPage from './index.js'

const ElectionsOnlineVotingSystemPage = () => (
  <IndexPage
    title="Create Elections with nVotes' Online Voting System"
    description="Grant legitimacy to your election with our auditable online voting system"
    showCallToAction={true}
  />
)

export default ElectionsOnlineVotingSystemPage

